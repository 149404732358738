import { Content, asImageSrc } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { PrismicNextImage } from '@prismicio/next';
import { useEffect, useState } from 'react';
import clsx from 'clsx';

/**
 * Props for `Testimonial`.
 */
export type TestimonialProps = SliceComponentProps<Content.TestimonialSlice>;

/**
 * Component for "Testimonial" Slices.
 */
const Testimonial = ({ slice }: TestimonialProps): JSX.Element => {
  const { background, quote_text, name_designation_line_1, name_designation_line_2, logo, image } = slice.primary;
  const [bg, setBg] = useState(background);
  
  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    }
  }, [])

  if (slice.variation === 'testimonialWithBoldParts') {
    return (
      <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        data-background={background}
        className={clsx(
          'wide-container color-changing-section',
          '[ transition-colors ease-in-out duration-1500 ][ w-full ]',
          {
            '[ bg-gray-97 ]': bg === 'light',
            '[ bg-gray-0 ]': bg === 'dark',
            '[ bg-blue-20 ]': bg === 'blue',
            '[ bg-blue-97 ]': bg === 'light_blue',
          },
        )}
      >
        <div className="[ text-center ][ flex flex-col items-center ][ w-full ][ px-5 md:px-16 pt-8 md:pt-10 pb-12 md:pb-20 lg:pb-24 ]">
          <div
            className={clsx(
              '[ text-20 tracking-tight ][ max-w-3xl ][ [&_p]:mb-11 ]',
              {
                '[ [&_p]:text-black/50 [&_strong]:text-gray-0 ]': bg === 'light',
                '[ [&_p]:text-gray-40 [&_strong]:text-white ]': bg !== 'light',
              },
            )}
          >
            <PrismicRichText field={quote_text} applyStyles={false} />
          </div>

          {asImageSrc(image) && (
            <p className="[ rounded-full ][ flex justify-center items-center ][ w-37.5 h-37.5 overflow-hidden ][ mb-4 ]">
              <PrismicNextImage field={image} width={150} height={150} />
            </p>
          )}

          <footer>
            <span
              data-testid="name_designation_line_1"
              className={clsx(
                '[ text-20 font-medium ][ block ]',
                {
                  '[ text-gray-0 ]': bg === 'light',
                  '[ text-white ]': bg !== 'light',
                },
              )}
            >
                {name_designation_line_1}
            </span>

            <span
              data-testid="name_designation_line_2"
              className={clsx(
                '[ text-14 ][ block ]',
                {
                  '[ text-gray-0 ]': bg === 'light',
                  '[ text-white ]': bg !== 'light',
                },
              )}
            >
                {name_designation_line_2}
            </span>
          </footer>
        </div>
      </section>
    );
  }
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={clsx(
        'wide-container',
        '[ transition-colors ease-in-out duration-1500 ][ w-full ]',
        {
          '[ bg-gray-97 ]': background === 'light',
          '[ bg-gray-0 ]': background === 'dark',
          '[ bg-blue-20 ]': background === 'blue',
          '[ bg-blue-97 ]': background === 'light_blue',
        },
      )}
    >
      <div className="[ text-center ][ flex flex-col items-center ][ w-full ][ px-5 md:px-16 py-16 md:py-28 ]">
        <div
          className={clsx(
            '[ text-28 font-medium tracking-tight ][ max-w-3xl ][ [&_p]:mb-10 ]',
            {
              '[ text-white ]': background === 'dark',
              '[ text-gray-0 ]': background !== 'dark',
            },
          )}
        >
          <PrismicRichText field={quote_text} applyStyles={false} />
        </div>

        {asImageSrc(image) && (
          <p className="[ rounded-full ][ flex justify-center items-center ][ w-37.5 h-37.5 overflow-hidden ][ mb-4 ]">
            <PrismicNextImage field={image} width={150} height={150} />
          </p>
        )}

        <footer>
          <span
            data-testid="name_designation_line_1"
            className={clsx(
              '[ text-20 font-medium ][ block ]',
              {
                '[ text-white ]': background === 'dark',
                '[ text-gray-0 ]': background !== 'dark',
              },
            )}
          >
            {name_designation_line_1}
          </span>

          <span
            data-testid="name_designation_line_2"
            className={clsx(
              '[ text-14 ][ block ]',
              {
                '[ text-white ]': background === 'dark',
                '[ text-gray-30 ]': background !== 'dark',
              },
            )}
          >
            {name_designation_line_2}
          </span>
        </footer>
      </div>
    </section>
  );
};

export default Testimonial;
